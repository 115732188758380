const translations = require('../assets/locales/en.json')
const _ = require('lodash')

const tmpBabel = require('./misc/tmpBabel.json')

// sometimes shakapacker in production doesn't rebuild babel
const forceRebuildToken = require('./misc/forceBabelRebuild')

window.__localization = {
  locale: 'en',
  translations: _.merge({}, {en: tmpBabel["en"]}, translations)
}
